import React from "react";
import useQuery from "../../helpers/useQuery";

const Redirect = () => {
  const query = useQuery();
  React.useEffect(() => {
    const token = query.get("token");

    if (token) {
      localStorage.setItem("token", token);
      window.location.href = "https://attend.skxv.dev";
      return;
    } else {
      window.location.href = "https://attend.skxv.dev";
      return;
    }
  }, []);
  return <div>Redirect</div>;
};

export default Redirect;
