import React from "react";
import axios from "axios";

import "./CreateRoom.scss";
import { Loader2, Upload, X } from "tabler-icons-react";

const CreateRoom = (props) => {
  const filePicker = React.useRef(null);
  const [file, setFile] = React.useState(null);
  const [name, setName] = React.useState(props.room ? props.room.name : "");
  const [coverPath, setCoverPath] = React.useState(
    props.room ? props.room.cover : null
  );
  const [coverSource, setCoverSource] = React.useState(
    props.room ? props.room.coverSource : ""
  );
  const [fileLoading, setFileLoading] = React.useState(false);

  const createRoom = async (user) => {
    await axios
      .post("https://zbc.up.railway.app/room", {
        name: name,
        id: user._id,
        cover: coverPath ? coverPath : null,
        coverSource: coverSource ? coverSource : null,
      })
      .then((res) => {
        props.getRooms();
        props.close();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveRoom = async (id) => {
    await axios
      .post("https://zbc.up.railway.app/room/save", {
        name: name,
        id: id,
        cover: coverPath ? coverPath : null,
        coverSource: coverSource ? coverSource : null,
      })
      .then((res) => {
        props.getRoom();
        props.close();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFileChange = async (e) => {
    setFileLoading(true);
    const file = e.target.files[0];
    const form = new FormData();
    form.append("file", file);
    form.append("user", props.user._id);

    await axios
      .post("https://skxv-id-production.up.railway.app/file", form, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setCoverPath(res.data.url);
        setFileLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className="CreateRoom">
      <div className="headline">
        <button onClick={() => props.close()}>
          <X size={24} />
        </button>
        <h1>{props.type === "edit" ? "Edit Room" : "Create Room"}</h1>
      </div>
      <h3 className="label">Select cover</h3>
      <div className="filePicker" onClick={() => filePicker.current.click()}>
        {coverPath && <img src={coverPath} alt="" />}
        {fileLoading ? (
          <Loader2 size={38} className="loader-spinner" />
        ) : (
          <Upload size={38} />
        )}
        <input
          type="file"
          accept=".jpg,.jpeg,.png,.gif,.webp"
          style={{ display: "none" }}
          ref={filePicker}
          onChange={(e) => handleFileChange(e)}
        />
      </div>
      {coverPath && (
        <>
          <button
            onClick={() => {
              setCoverPath(null);
              setCoverSource(null);
            }}
          >
            Remove cover
          </button>
          <input
            type="text"
            placeholder="Where did you get this image?"
            value={coverSource}
            onChange={(e) => setCoverSource(e.target.value)}
          />
        </>
      )}
      <input
        type="text"
        placeholder="Enter room name..."
        className="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <button
        onClick={async () => {
          if (props.type === "edit") {
            await saveRoom(props.room._id);
          } else {
            await createRoom(props.user);
          }
        }}
        disabled={fileLoading || name.length < 3}
      >
        {fileLoading ? (
          <>
            <Loader2 size={16} className="loader-spinner" />
            Uploading Cover...
          </>
        ) : (
          <>{props.type === "edit" ? "Save room" : "Create room"}</>
        )}
      </button>
    </div>
  );
};

export default CreateRoom;
