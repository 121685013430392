import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import Attend from './routes/Attend/Attend';
import Dashboard from './routes/Dashboard/Dashboard';
import Login from './routes/Login/Login';
import Redirect from './routes/Redirect/Redirect';
import { ModalManager } from './helpers/ModalManager';

import "./global.scss";

const App = () => {
  return (
    <ModalManager>
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/attend" element={<Attend />} />
          <Route path="/login" element={<Login />} />
          <Route path="/redirect" element={<Redirect />} />
        </Routes>
      </Router>
    </div>
    </ModalManager>
  );
}

export default App;
