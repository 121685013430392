import React from "react";
import "./Attend.scss";
import useQuery from "../../helpers/useQuery";
import axios from "axios";
import { Loader2 } from "tabler-icons-react";

const Attend = () => {
  const [success, setSuccess] = React.useState(false);
  const query = useQuery();
  const [room, setRoom] = React.useState(null);
  const [roomId, setRoomId] = React.useState(query.get("r"));
  const [date, setDate] = React.useState(query.get("d"));
  const [deviceValid, setDeviceValid] = React.useState(false);
  const [accepted, setAccepted] = React.useState(false);

  const [loading, setLoading] = React.useState(true);

  const [name, setName] = React.useState("");
  const [course, setCourse] = React.useState("");

  const getRoom = async () => {
    await axios
      .get(`https://zbc.up.railway.app/room?id=${roomId}`)
      .then((res) => {
        if (res.data.open) {
          if (date == res.data.date) {
            setRoom(res.data);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  const registerAttendance = async () => {
    if (!name || !course) {
      return;
    }

    await axios
      .post("https://zbc.up.railway.app/attend", {
        name: name,
        course: course,
        room: roomId,
        date: Date.now(),
      })
      .then((res) => {
        setSuccess(true);
        localStorage.setItem("attendee", res.data._id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkDevice = async () => {
    const attendee = localStorage.getItem("attendee");

    await axios
      .get(`https://zbc.up.railway.app/attendee?id=${attendee}`)
      .then((res) => {
        console.log("res.data", res.data);
        if (res.data.found) {
          setDeviceValid(false);
        } else {
          setDeviceValid(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  React.useEffect(() => {
    if (localStorage.getItem("attendee")) {
      checkDevice();
    } else {
      setDeviceValid(true);
    }
  }, []);

  React.useEffect(() => {
    if (!room && deviceValid) {
      setLoading(true);
      getRoom();
    }
  }, [deviceValid]);

  return (
    <>
      {loading ? (
        <div className="Attend-loading">
          <Loader2 size={90} className="loader-spinner" />
        </div>
      ) : (
        <div
          className={`Attend ${!room ? "error" : ""} ${
            success ? "success" : ""
          }`}
        >
          {room ? (
            <>
              {!success ? (
                <div className="wrapper">
                  <h2>Deltag i {room.name}</h2>
                  <div className="form">
                    <input
                      type="text"
                      placeholder="Navn"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      autoCapitalize="off"
                      autoComplete="off"
                    />
                    <select
                      value={course}
                      onChange={(e) => setCourse(e.target.value)}
                    >
                      <option value="0">Vælg hold</option>
                      {room.courses.map((course) => (
                        <option key={course.name} value={course.name}>
                          {course.name}
                        </option>
                      ))}
                    </select>
                    <div className="checkbox">
                      <div className="checkbox-input">
                        <input
                          type="checkbox"
                          onChange={(e) => setAccepted(!accepted)}
                          checked={accepted}
                        />
                      </div>
                      <p onClick={() => setAccepted(!accepted)}>
                        Ved at sætte flueben i denne boks, acceptere du at de
                        informationer du har indtastet, vil blive gemt i en
                        database i maksimum 48 timer.
                      </p>
                    </div>
                    <button
                      onClick={() => registerAttendance()}
                      disabled={!accepted || !name || course == "0"}
                    >
                      Registrer tilstedeværelse
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="wrapper">
                    <h2>Attendance was registered successfully!</h2>
                    <p>You can now close this page</p>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {deviceValid ? (
                <div className="wrapper">
                  <h2>This link has expired</h2>
                  <p>Please try scanning the code again</p>
                </div>
              ) : (
                <h2>Device is already registered</h2>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Attend;
