import React from "react";
import "./Dashboard.scss";
import useQuery from "../../helpers/useQuery";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import Room from "../Room/Room";
import { ArrowUpRight, Trash } from "tabler-icons-react";
import { useModal } from "../../helpers/ModalManager";

import CreateRoom from "../../components/CreateRoom/CreateRoom";
import Account from "../../components/Account/Account";

const Dashboard = () => {
  const navigate = useNavigate();
  const { create, close } = useModal();
  const filePicker = React.useRef(null);
  const [user, setUser] = React.useState(null);
  const [admin, setAdmin] = React.useState(null);
  const [rooms, setRooms] = React.useState(null);

  const [inputRoomName, setInputRoomName] = React.useState("");

  const query = useQuery();

  const getUser = async (token) => {
    await axios
      .get("https://skxv-id-production.up.railway.app/user", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setUser(res.data.user);
      })
      .catch((err) => {
        console.log(err);
        localStorage.removeItem("token");
      });
  };

  const getAdmin = async () => {
    await axios
      .get(`https://zbc.up.railway.app/user?id=${user._id}`)
      .then((res) => {
        setAdmin(res.data.user);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRooms = async () => {
    await axios
      .get(`https://zbc.up.railway.app/rooms?id=${user._id}`)
      .then((res) => {
        setRooms(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const createRoom = async () => {
    /*await axios
      .post("https://zbc.up.railway.app/room", {
        name: inputRoomName,
        id: user._id,
      })
      .then((res) => {
        console.log(res);
        getRooms();
      })
      .catch((err) => {
        console.log(err);
      });*/

    //filePicker.current.click();
    const id = Date.now();
    create({
      id,
      title: false,
      body: (
        <CreateRoom
          close={() => close(id)}
          user={user}
          getRooms={() => getRooms()}
        />
      ),
      close: "Nevermind",
      buttons: false,
      onClose: () => close(id),
    });
  };

  const deleteRoom = async (id) => {
    await axios
      .delete(`https://zbc.up.railway.app/room?id=${id}`)
      .then((res) => {
        console.log(res);
        getRooms();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      getUser(localStorage.getItem("token"));
      return;
    }

    window.location.href =
      "https://id.skxv.dev/auth?redirect=https://attend.skxv.dev/redirect";
  }, []);

  React.useEffect(() => {
    if (user) {
      getAdmin();
    }
  }, [user]);

  React.useEffect(() => {
    if (admin) {
      getRooms();
    }
  }, [admin]);

  return (
    <div className="Dashboard">
      {user && (
        <>
          {query.get("room") ? (
            <>
              <Room room={query.get("room")} user={user} />
            </>
          ) : (
            <>
              <div className="headline">
                <h1>Dashboard</h1>
                <div className="col">
                  <button onClick={() => createRoom()}>Create room</button>
                  <Account user={user} admin={admin} />
                </div>
              </div>
              <div className="rooms">
                <table>
                  <tr>
                    <th>Room Name</th>
                    <th>Attendees</th>
                    <th>Open</th>
                    <th>Actions</th>
                  </tr>
                  {rooms &&
                    rooms.map((room) => {
                      return (
                        <tr>
                          <td>{room.name}</td>
                          <td>{room.attendees}</td>
                          <td>{room.open ? "Yes" : "No"}</td>
                          <td className="actions">
                            {/*<a href="#">delete</a>
                            {" | "}
                      <a href={`?room=${room._id}`}>open</a>*/}
                            <button
                              className="action danger"
                              onClick={() => deleteRoom(room._id)}
                            >
                              <Trash />
                            </button>
                            <button
                              className="action primary"
                              onClick={() => navigate(`?room=${room._id}`)}
                            >
                              <ArrowUpRight />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </table>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Dashboard;
