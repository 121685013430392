import React from "react";
import axios from "axios";
import useQuery from "../../helpers/useQuery";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";

import "./Room.scss";
import {
  ChevronLeft,
  Download,
  Loader2,
  Maximize,
  Pencil,
  PlayerPlay,
  PlayerStop,
  Trash,
} from "tabler-icons-react";

import CreateRoom from "../../components/CreateRoom/CreateRoom";

import { useModal } from "../../helpers/ModalManager";
import Select from "../../components/Select/Select";

const Room = (props) => {
  const [room, setRoom] = React.useState(null);
  const [attendees, setAttendees] = React.useState([]);
  const [filteredAttendees, setFilteredAttendees] = React.useState([]);
  const [timer, setTimer] = React.useState(null);
  const [attendeesTimer, setAttendeesTimer] = React.useState(null);
  const [fullscreen, setFullscreen] = React.useState(false);
  const { create, close } = useModal();
  const [courseName, setCourseName] = React.useState("");
  const [courseLoading, setCourseLoading] = React.useState(false);
  const [filter, setFilter] = React.useState("");

  const navigate = useNavigate();

  const getRoom = async (req, res) => {
    await axios
      .get(`https://zbc.up.railway.app/room?id=${props.room}`)
      .then((res) => {
        setRoom(res.data);
        setCourseLoading(false);
      })
      .catch((err) => {
        console.log(err);
        navigate("/");
      });
  };

  const toggleRoom = async () => {
    await axios
      .post("https://zbc.up.railway.app/room/toggle", {
        id: room._id,
        open: !room.open,
        date: Date.now(),
      })
      .then((res) => {
        setRoom(res.data);
        if (res.data.open) {
          startTimer();
        } else {
          stopTimer();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const startTimer = async () => {
    setTimer(
      setInterval(() => {
        axios
          .post("https://zbc.up.railway.app/room/date", {
            id: room._id,
            date: Date.now(),
          })
          .then((res) => {
            setRoom(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }, 15000)
    );
  };

  const getNewAttendees = async () => {
    await axios
      .get(`https://zbc.up.railway.app/attendees?id=${room._id}`)
      .then((res) => {
        setAttendees(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAttendees = async () => {
    setAttendeesTimer(
      setInterval(async () => {
        getNewAttendees();
      }, 3000)
    );
  };

  const stopTimer = () => {
    clearInterval(timer);
  };

  const openFullscreen = () => {
    setFullscreen(true);

    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    }
  };

  const closeFullscreen = () => {
    setFullscreen(false);

    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
  };

  const deleteAttendee = async (attendee) => {
    await axios
      .delete("https://zbc.up.railway.app/attendee", {
        data: {
          id: attendee,
        },
      })
      .then((res) => {
        console.log(res);
        getNewAttendees();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    if (room) {
      if (room.open) {
        getAttendees();
      }
    }
  }, [room]);

  React.useEffect(() => {
    getRoom();
  }, []);

  React.useEffect(() => {
    if (filter) {
      setFilteredAttendees(
        attendees.filter((attendee) => attendee.course === filter)
      );
    } else {
      setFilteredAttendees(attendees);
    }
  }, [filter, attendees]);

  React.useEffect(() => {
    function handleFullscreenChange() {
      if (!document.fullscreenElement) {
        setFullscreen(false);
      }
    }

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const generateCsv = () => {
    let data = [
      ["Name", "Course", "Joined"],
      ...filteredAttendees.map((attendee) => [
        attendee.name,
        attendee.course,
        new Date(attendee.attended).toLocaleString("da-DK", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }),
      ]),
    ];

    let csvContent = "data:text/csv;charset=utf-8,";
    data.forEach(function (rowArray) {
      let row = rowArray.join(",");
      csvContent += row + "\r\n";
    });

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `${room.name.replaceAll(" ", "-").replaceAll(",", "")}-${filter
        .replaceAll(" ", "-")
        .replaceAll(",", "")}-attendees.csv`
    );
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "my_data.csv".
  };

  const editRoom = () => {
    const id = Date.now();
    create({
      id,
      title: false,
      body: (
        <CreateRoom
          close={() => close(id)}
          getRoom={() => getRoom()}
          user={props.user}
          type="edit"
          room={room}
        />
      ),
      close: "Nevermind",
      buttons: false,
      onClose: () => close(id),
    });
  };

  const addCourse = async () => {
    const courses = [...room.courses, { id: Date.now(), name: courseName }];
    setCourseLoading(true);
    await axios
      .post("https://zbc.up.railway.app/room/courses", {
        id: room._id,
        courses: courses,
      })
      .then((res) => {
        setRoom(res.data);
        setCourseName("");
        setCourseLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteCourse = async (id) => {
    const courses = room.courses.filter((course) => course.id !== id);
    await axios
      .post("https://zbc.up.railway.app/room/courses", {
        id: room._id,
        courses: courses,
      })
      .then((res) => {
        setRoom(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="Room">
      {room && (
        <>
          {fullscreen && (
            <div
              className="fullscreen"
              onClick={() => closeFullscreen()}
              style={{
                background: room.cover ? `url(${room.cover})` : "white",
              }}
            >
              <div className="wrapper">
                <div className="qr-container">
                  <QRCode
                    value={`http://attend.skxv.dev/attend?r=${room._id}&d=${room.date}`}
                    className="qr"
                    size={556}
                  />
                </div>
                <div className="text">
                  <h1>{room.name}</h1>
                  <h2>
                    Use the camera app on your phone to scan the QR code, to
                    register your attendance
                  </h2>
                </div>
              </div>
              {room.cover && room.coverSource && (
                <span className="coverSource">
                  Cover Source:{" "}
                  <a
                    href={room.coverSource}
                    target="_blank"
                    rel="noreferrer"
                    className="source"
                  >
                    {room.coverSource}
                  </a>
                </span>
              )}
            </div>
          )}
          <div className="headline">
            <div className="name">
              <button className="back" onClick={() => navigate("/")}>
                <ChevronLeft />
              </button>
              <h1>{room.name}</h1>
            </div>
            <div className="actions">
              {room.open && (
                <button onClick={() => openFullscreen()}>
                  <Maximize size={16} />
                  Open fullscreen
                </button>
              )}
              <button
                onClick={() => toggleRoom()}
                className={`${room.open ? "danger" : "success"}`}
              >
                {room.open ? (
                  <PlayerStop size={16} />
                ) : (
                  <PlayerPlay size={16} />
                )}
                {room.open ? "Close Room" : "Open Room"}
              </button>
              <button onClick={() => editRoom()}>
                <Pencil size={16} />
                Edit room
              </button>
            </div>
          </div>
          {room.open ? (
            <>
              <div className="details">
                <div className="qr-container">
                  <QRCode
                    value={`https://attend.skxv.dev/attend?r=${room._id}&d=${room.date}`}
                    className="qr"
                  />
                  <a
                    href={`/attend?r=${room._id}&d=${room.date}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Open
                  </a>
                  <div className="addCourse">
                    <input
                      type="text"
                      placeholder="Course name"
                      onChange={(e) => setCourseName(e.target.value)}
                      value={courseName}
                    />
                    <button
                      onClick={() => addCourse()}
                      disabled={courseLoading}
                    >
                      {courseLoading && (
                        <Loader2 size={16} className="loader-spinner" />
                      )}
                      <span>Add</span>
                    </button>
                  </div>
                  <table>
                    <tr>
                      <th>Course</th>
                      <th>Actions</th>
                    </tr>
                    {room.courses.map((course) => (
                      <tr>
                        <td>{course.name}</td>
                        <td>
                          <button
                            className="action danger"
                            onClick={() => deleteCourse(course.id)}
                          >
                            <Trash />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>
                <div className="attendees">
                  <div className="headline">
                    <div className="title">
                      <h3>Attendees</h3>
                    </div>
                    <div className="actions">
                      <select
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                      >
                        <option value="">All</option>
                        {room.courses.map((course) => (
                          <option value={course.name}>{course.name}</option>
                        ))}
                      </select>
                      {/*<Select
                        options={room.courses}
                        onChange={(e) => setFilter(e)}
                        placeholder="Course filter"
                    />*/}
                      <button onClick={() => generateCsv()}>
                        <Download size={16} />
                        Export
                      </button>
                    </div>
                  </div>
                  <table>
                    <tr>
                      <th>Name</th>
                      <th>Course</th>
                      <th>Joined</th>
                      <th>Actions</th>
                    </tr>
                    {filteredAttendees.map((attendee) => (
                      <tr>
                        <td>{attendee.name}</td>
                        <td>{attendee.course}</td>
                        <td>
                          {new Date(attendee.attended).toLocaleString("da-DK", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </td>
                        <td>
                          <button
                            className="action danger"
                            onClick={() => deleteAttendee(attendee._id)}
                          >
                            <Trash />
                          </button>
                        </td>
                      </tr>
                    ))}
                    {attendees.length === 0 && (
                      <tr>
                        <td colSpan="4">No attendees yet</td>
                      </tr>
                    )}
                  </table>
                </div>
              </div>
            </>
          ) : (
            <h1 className="closed">
              Please open the room to start the attendance registration process
            </h1>
          )}
        </>
      )}
    </div>
  );
};

export default Room;
