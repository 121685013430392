import React from "react";
import { ChevronDown, Logout, User } from "tabler-icons-react";
import "./Account.scss";

const Account = (props) => {
  return (
    <div className="Account" tabIndex={0}>
      <img src={props.user.image} alt="" />
      <div className="text">
        <h2>{props.user.name}</h2>
        <p>{props.user.email}</p>
      </div>
      <ChevronDown />
      <div className="dropdown">
        <div
          className="item"
          onClick={() => {
            window.location.href = "https://id.skxv.dev";
          }}
        >
          <User />
          <p>Profile</p>
        </div>
        <div
          className="item logout"
          onClick={() => {
            localStorage.removeItem("token");
            window.location.reload();
          }}
        >
          <Logout />
          <p>Logout</p>
        </div>
      </div>
    </div>
  );
};

export default Account;
